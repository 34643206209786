import { AuthPage } from 'components/Auth';
import { loadContentfulGenericData } from 'lib/contentful/loadContentfulData';
import { normalizeLocale } from 'lib/normalizeLocale';
import { truckMapConfig } from 'truckMapConfig';

type Context = {
  locale: string;
};

export const getStaticProps = async ({ locale = truckMapConfig.i18n.defaultLocale }: Context) => {
  try {
    const {
      contentfulQuery,
      legacyLayoutResponse: [page]
    } = await loadContentfulGenericData({
      legacyRelativePath: '/map',
      variables: {
        locale: normalizeLocale(locale)
      }
    });

    return {
      props: { contentfulQuery, page },
      revalidate: 3600
    };
  } catch (error) {
    return {
      notFound: true
    };
  }
};

export default AuthPage;
